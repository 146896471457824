import React, { Component } from 'react';
import Lottie from 'react-lottie';

import getAvatarById from "constants/avatars";
import styles from "components/PlayerStyles.module.scss";

import plusOne from "animations/plusOne.js";
import plusTwo from "animations/plusTwo.js";


export default class Player extends Component {

    constructor(props) {
        super(props);
        let fontSize = this.getFontSize(props.player);
        this.state = {
            fontSize: fontSize,
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState({ fontSize: this.getFontSize(nextProps.player), });
    }


    getFontSize(player) {
        if (player) {
            let name = player.name;
            let fontSize = "";
            if (name.length <= 3) {
                fontSize = "large";
            } else if (name.length <= 6) {
                fontSize = "medium";
            } else {
                fontSize = "small";
            }
            return fontSize;
        } else {
            return "medium";
        }
    }

    getBigNameFontSize(text) {
        let textLength = text.length;
        let fontSize = "8vh";
        if (textLength <= 3) {
            fontSize = this.props.showAnswers ? "6.5vh" : "10vh";
        } else if (textLength <= 6) {
            fontSize = this.props.showAnswers ? "4.75vh" : "8vh";
        } else {
            fontSize = this.props.showAnswers ? "3.5vh" : "6vh";
        }
        return fontSize;
    }


    render() {
        return (
            <div id={`player-${this.props.player.id}`} className={styles.player}>
                <div className={`${styles.readySection} ${this.props.player.hasAnswered ? styles.show : ""}`} >
                    <div className={styles.readyText}>READY</div>
                </div>
                <div className={`
                        ${styles.answerSection} 
                        ${this.props.player.showAnswer ? styles.show : ""}
                    `}
                >
                    {/*<img*/}
                    {/*    className={`${styles.answerImg} ${this.props.player.animAnswer ? styles.show : ""}`}*/}
                    {/*    src={*/}
                    {/*        this.props.player.validateAnswer ?*/}
                    {/*            this.props.player.isCorrect ?*/}
                    {/*                this.props.player.answerText == "agree" ? agreeG : disagreeG*/}
                    {/*                :*/}
                    {/*                this.props.player.answerText == "agree" ? agreeR : disagreeR*/}
                    {/*            :*/}
                    {/*            this.props.player.answerText == "agree" ? agreeW : disagreeW*/}
                    {/*    }*/}
                    {/*/>*/}
                    <div className={`${styles.answerImg}   ${this.props.player.validateAnswer ? this.props.player.isCorrect ? styles.correct : styles.wrong : ""}`}>
                        {this.props.player.answerText}
                    </div>
                    <div className={styles.corner} />
                    <div className={`${styles.bonusAnswer} ${this.props.player.bonusText ? styles.show : ""}`}>{this.props.player.bonusText}</div>
                    {
                        this.props.player.validateAnswer ?
                            this.props.player.isCorrect ?
                                <Lottie options={this.props.player.answerText == "disagree" ? plusOne : plusTwo} height="10vh" isClickToPauseDisabled={true} style={{ position: 'absolute', left: "9vh", zIndex: 3, }} />
                                :
                                null
                            :
                            null
                    }
                </div>
                {/*{*/}
                {/*    !this.props.player.connected ?*/}
                {/*        <div className={styles.countdownContainer}>*/}
                {/*            Disconnecting In:*/}
                {/*            <br />*/}
                {/*            {this.props.player.connectingTimer}*/}
                {/*        </div>*/}
                {/*        :*/}
                {/*        null*/}
                {/*}*/}
                {/*<img*/}
                {/*    src={avatars[this.props.player.avatar].src}*/}
                {/*    id={`potato-${this.props.player.id}`}*/}
                {/*    name={`potato-${this.props.player.id}`}*/}
                {/*    className={`${styles.potato}`}*/}
                {/*    style={this.props.vsPlayers.length > 0 ? this.props.vsPlayers[0].id == this.props.player.id ? { top: `${this.props.movePosOne.top}px`, left: `${this.props.movePosOne.left}px`, height: "20vh", } : this.props.vsPlayers[1].id == this.props.player.id ? { top: `${this.props.movePosTwo.top}px`, left: `${this.props.movePosTwo.left}px`, height: "20vh", } : {} : {}}*/}
                {/*/>*/}
                <div
                    id={`potato-${this.props.player.id}`}
                    name={`potato-${this.props.player.id}`}
                    className={`${styles.potato} ${this.props.isVsPlayer ? this.props.showAnswers ? "" : this.props.vsPlayers[0].id == this.props.player.id ? this.props.movePotOne ? styles.bigger : "" : this.props.vsPlayers[1].id == this.props.player.id ? this.props.movePotTwo ? styles.bigger : "" : "" : styles.centered}`}
                    style={this.props.isVsPlayer ? this.props.vsPlayers[0].id == this.props.player.id ? this.props.movePotOne ? { top: `${this.props.movePosOne.top}px`, left: `${this.props.movePosOne.left}px`, height: "20vh", width: "20vh" } : {} : this.props.vsPlayers[1].id == this.props.player.id ? this.props.movePotTwo ? { top: `${this.props.movePosTwo.top}px`, left: `${this.props.movePosTwo.left}px`, height: "20vh", width: "20vh" } : {} : {} : {}}
                >
                    <div id={`anim-potato-${this.props.player.id}`} style={{ width: "100%", height: "100%", }}>
                        <Lottie
                            options={getAvatarById(this.props.player.avatar).idleAnim}
                            isClickToPauseDisabled={true}
                            height="100%"
                            width="100%"
                        />
                    </div>
                    <div className={`${styles.bigName} ${this.props.showAnswers ? styles.smaller : ""} ${this.props.isVsPlayer ? styles.show : ""}`} style={{ fontSize: this.getBigNameFontSize(this.props.player.name) }}>{this.props.player.name}</div>
                </div>
                <img
                    src={getAvatarById(this.props.player.avatar).src}
                    className={`${styles.potato} ${styles.centered} ${this.props.isVsPlayer ? styles.fade : styles.hidden}`}
                />
                <div className={`${styles.textSection} ${this.props.doPodium ? styles.colorSwap : ""}` }>
                    <div id="nameText" className={`${styles.nameText} ${this.state.fontSize == "large" ? styles.large : this.state.fontSize == "medium" ? styles.medium : styles.small} ${this.props.doPodium ? styles.colorSwap : ""}`}>{this.props.player.name}</div>
                    <div id="scoreText" className={`${styles.nameText} ${styles.score} ${this.props.doPodium ? styles.colorSwap : ""}`}>{this.props.player.cosData.score}</div>
                </div>
                <div id="podium" name="podium" className={styles.podium}></div>
            </div>
        )
    }
}