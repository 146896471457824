import agreeGreen from "../images/agree_green.png";
import agreeRed from "../images/agree_red.png";
import agreeWhite from "../images/agree_white.png";
import bubbleTick from "../images/bubbleTick.png";
import cosLogo from "../images/cos-logo.png";
import disagreeGreen from "../images/disagree_green.png";
import disagreeRed from "../images/disagree_red.png";
import disagreeWhite from "../images/disagree_white.png";
import muted from "../images/muted.png";
import potato from "../images/potato.png";
import squirmsFullscreen from "../images/squirms_fullscreen.png";
import squirmsHelp from "../images/squirms_help.png";
import squirmsMuted from "../images/squirms_muted.png";
import squirmsUnmuted from "../images/squirms_unmuted.png";
import unmuted from "../images/unmuted.png";
import vs from "../images/vs.png";
import bgHorizontal from "../animations/bg_horizontal.gif";
import bgVertical from "../animations/bg_vertical";

const images = [
    agreeGreen,
    agreeRed,
    agreeWhite,
    bubbleTick,
    cosLogo,
    disagreeGreen,
    disagreeRed,
    disagreeWhite,
    muted,
    potato,
    squirmsFullscreen,
    squirmsHelp,
    squirmsMuted,
    squirmsUnmuted,
    unmuted,
    vs,
    bgHorizontal,
    bgVertical,
];

const fonts = [
    { font: "Interstate-Bold", path: `https://${window.location.host}/fonts/Interstate-Bold.ttf` },
    { font: "Interstate-Regular", path: `https://${window.location.host}/fonts/Interstate-Regular.otf` },
    { font: "Juri-Zaech", path: `https://${window.location.host}/fonts/JuriZaech-FrontageCondensedBold.otf` },
    { font: "Pintassilgo", path: `https://${window.location.host}/fonts/PintassilgoPrints-UnpackRegular.ttf` },
];

export default {
    images,
    fonts,
};